<template>
  <ProjectLayout
    :displayedCard="'opened-hardware-booking'"
    v-if="position"
  >
    <b-card
      align="left"
      class="booking-opened-card side-card positions"
      header-tag="header"
      no-body
    >
      <b-card-header>
        <b-container>
          <b-row v-if="broadcasted">
            <b-col class="header-info">
              <h4>{{ position.title }}</h4>
              <div class="position-side">
                <div v-if="position.getOpened" class="opened-position pink text-nowrap">
                  <span class="text-position d-none d-sm-inline-block">posición abierta</span>
                  <img
                    alt="Posición abierta"
                    class="pink"
                    :src="require('@/assets/icons/booking-opened-pink.svg')"
                  >
                </div>
                <div v-if="!position.getOpened" class="closed-position text-nowrap">
                  <span class="text-position d-none d-sm-inline-block">posición cerrada</span>
                  <img
                    alt="Posición cerrada"
                    :src="require('@/assets/icons/booking-closed.svg')"
                  >
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <div class="d-flex flex-column line-space position-info" v-if="positionLoaded">
                <span class="text">
                  Fecha:
                  <span class="pink font-weight-bold">{{ positionDate }}</span>
                </span>
                <span class="text">
                  Hora:
                  <span class="pink font-weight-bold">{{ positionTime }}</span>
                </span>

                <span class="text">
                  Cantidad:
                  <span class="pink font-weight-bold" v-if="position">{{ position.pricePerDay }}</span>
                </span>

                <span class="text">
                  Anotaciones:
                  <span class="pink font-weight-bold">{{ position.annotation }}</span>
                </span>
              </div>
              <div v-else>
                <span>Cargando...</span>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </b-card-header>
    </b-card>
    <b-card
      align="left"
      class="booking-opened-card side-card positions"
      v-if="booking && !position.getOpened && contactedTechnicians"
      header-tag="header"
      no-body
    >
      <template #header>
        <b-row>
          <b-col>
            <h4>Técnico seleccionado</h4>
          </b-col>
        </b-row>
      </template>
      <b-card-body>
        <b-container>
          <b-row
            v-for="contactedTechnician in selectedTechnicians"
            :key="contactedTechnician.id"
          >
            <b-col v-if="contactedTechnician.businessAccepted">
              <ContactedHardware
                :bookingId="booking.id"
                :contactedTechnician="contactedTechnician"
                :position="position"
                :showButtons="position.getOpened"
                :showCancelPosition="!position.getOpened"
                @updateData="loadData"
              />
            </b-col>
          </b-row>
        </b-container>
      </b-card-body>
      <b-card-body v-if="!position.getOpened && contactedTechnicians == null">
        <b-container>
          <b-row>
            <b-col>
              Cargando...
            </b-col>
          </b-row>
        </b-container>
      </b-card-body>
    </b-card>
    <b-card
      align="left"
      class="booking-opened-card side-card positions contacted-technicians"
      v-if="booking && listTechniciansRequests.length > 0"
      header-tag="header"
      no-body
    >
      <template #header>
        <b-row>
          <b-col>
            <h4>Solicitudes</h4>
          </b-col>
        </b-row>
      </template>
      <b-card-body v-if="contactedTechnicians" class="list-position-technician">
        <b-container v-if="listTechniciansRequests.length > 0">
          <b-row
            v-for="contactedTechnician in listTechniciansRequests"
            :key="contactedTechnician.id"
          >
            <b-col v-if="!contactedTechnician.businessAccepted">
              <ContactedHardware
                :bookingId="booking.id"
                :contactedTechnician="contactedTechnician"
                :position="position"
                :showButtons="position.getOpened"
                @updateData="loadData"
              />
              <hr class="divider" />
            </b-col>
          </b-row>
        </b-container>
        <b-container v-else>
          <b-row>
            <b-col>
              <div>
                <strong>¡Vaya!</strong>
              </div>
              Parece que no tienes ninguna solicitud
            </b-col>
          </b-row>
        </b-container>
      </b-card-body>
      <b-card-body v-if="contactedTechnicians == null">
        <b-container>
          <b-row>
            <b-col>
              Cargando...
            </b-col>
          </b-row>
        </b-container>
      </b-card-body>
    </b-card>
  </ProjectLayout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ProjectLayout from '@/views/project/layout/ProjectLayout.vue'
import ContactedHardware from '@/components/Business/Project/HardwarePosition/Contacted/ContactedHardware.vue'

export default {
  name: 'DetailHardwarePosition',
  components: {
    ProjectLayout,
    ContactedHardware
  },
  data () {
    return {
      contactedTechnicians: null
    }
  },
  created: async function () {
    this.getHardwareBookingById({
      projectId: this.$route.params.projectId,
      id: this.$route.params.hardwareBookingId
    })
    const payload = {
      bookingId: this.$route.params.hardwareBookingId,
      bookingPositionId: this.$route.params.hardwarePositionId
    }
    this.getHardwareBookingPositionById(payload)
    this.contactedTechnicians = await this.getContactedHardwareFromBusiness(payload)
  },
  computed: {
    ...mapGetters({ booking: 'hardwareBooking/current' }),
    ...mapGetters({ position: 'hardwarePosition/current' }),
    visibleItemMenu () {
      if (this.booking) {
        if (!this.booking.getOpened) {
          return 'closed'
        }
        return 'opened'
      }
      return ''
    },
    positionLoaded () {
      return this.position !== null
    },
    broadcasted () {
      if (!this.booking) return
      return this.booking.broadcasted
    },
    listTechniciansRequests () {
      if (this.contactedTechnicians === null) return []
      return this.contactedTechnicians.filter(contactedTechnician => {
        return !contactedTechnician.businessAccepted
      })
    },
    selectedTechnicians () {
      if (this.contactedTechnicians === null) return []
      return this.contactedTechnicians.filter(contactedTechnician => {
        return contactedTechnician.businessAccepted
      })
    },
    positionDate () {
      return this.position.getEventDate
    },
    positionTime () {
      return this.position.getOnlyTime
    }
  },
  methods: {
    ...mapActions('hardwareBooking', { getHardwareBookingById: 'getOneById' }),
    ...mapActions('hardwarePosition', { getHardwareBookingPositionById: 'getOneById' }),
    ...mapActions('hardwarePosition', ['getContactedHardwareFromBusiness']),
    joinArrayNames (array) {
      return array.map(item => { return item.name }).join(', ')
    },
    async loadData () {
      const payload = {
        bookingId: this.$route.params.hardwareBookingId,
        bookingPositionId: this.$route.params.hardwarePositionId
      }
      this.getHardwareBookingPositionById(payload)
      this.contactedTechnicians = await this.getContactedHardwareFromBusiness(payload)
    }
  }

}
</script>

<style lang="less" scoped>
@import "detailHardwarePosition";
</style>
